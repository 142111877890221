import {createTheme, responsiveFontSizes} from '@mui/material'
import CeraProBlackEOT from './fonts/CeraPro-Black.eot'
import CeraProBlackTTF from './fonts/CeraPro-Black.ttf'
import CeraProBlackWOFF from './fonts/CeraPro-Black.woff'

const primary = {
  main: '#8d0a2a',
}

let lraTheme = createTheme({
  typography: {
    h1: {
      fontFamily: 'CeraPro, Arial',
    },
    h2: {
      fontFamily: 'CeraPro, Arial',
    },
    h3: {
      fontFamily: 'CeraPro, Arial',
    },
    h4: {
      fontFamily: 'CeraPro, Arial',
    },
    h5: {
      fontFamily: 'CeraPro, Arial',
    },
    h6: {
      fontFamily: 'CeraPro, Arial',
    },
    lead: {
      color: '#fff',
      fontSize: 22,

    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: primary.main,
    },
    button: {
      fontWeight: 700,
    },

    fontFamily: 'IBM Plex Sans, Roboto, Helvetica, sans-serif',
  },
  palette: {
    primary: {
      main: primary.main,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'CeraPro';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src:  url(${CeraProBlackTTF}) format('truetype'), 
                url(${CeraProBlackWOFF}) format('woff'),
                url(${CeraProBlackEOT}) format('embedded-opentype');
        }
      `,
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'sidebar'},
          style: {
            minWidth: 'auto',
            background: primary.main,
            color: '#ffffff',
            borderRadius: '0',
            padding: 16,
          },
        },
        {
          props: {variant: 'neutral'},
          style: {
            borderRadius: 0,
            background: '#202929',
            textTransform: 'none',
            color: '#ffffff',
            '&:hover': {
              color: '#000000',
            },
          },
        },
        {
          props: {variant: 'neutral outlined'},
          style: {
            borderRadius: 0,
            background: 'none',
            border: '1px solid #000000',
            textTransform: 'none',
            '&:hover': {
              background: '#000000',
              color: '#ffffff',
            },
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          link: 'a',
        },
      },
    },
  },
})

lraTheme = responsiveFontSizes(lraTheme)

export default lraTheme