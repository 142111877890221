import {Button} from '@mui/material'
import React from 'react'

const CategoryBtn = ({category}) => {
  console.log('BTN CAT:', category)
  return <Button size={'small'}
                 sx={{
                   color: category?.color || 'inherit',
                 }}>{category?.name}</Button>
}

export default CategoryBtn