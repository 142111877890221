import React, {useContext, useEffect, useState} from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import {fetchAPI} from '../../lib/api'
import PostCard from './components/PostCard'

export default function News() {
  const [loading, setLoading] = useState(true)
  const [posts, setPosts] = useState(['', '', '', ''])

  useEffect(() => {
    const getPosts = async () => {
      const posts = await fetchAPI('/posts', {
        _embed: true, per_page: 4, _fields: [
          'id',
          'slug',
          'date',
          'title',
          'categories',
          'excerpt',
          'featured_media'],
      })
      setPosts(posts)
      setLoading(false)
    }
    getPosts().catch(console.error)

  }, [])

  return (<Box sx={{overflowX: 'scroll'}}>
    <Stack direction={'row'} spacing={3} sx={{my: 3}}
           justifyContent={{lg: 'center'}}>
      {posts.map((post, index) => <PostCard loading={loading} post={post}
                                            key={index}/>)}
    </Stack>
  </Box>)

}