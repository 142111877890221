import React, {useContext, useEffect, useState} from 'react'
import {GlobalCategories} from '../../../App'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import PostCardMedia from './CardMedia'
import LoadingButton from '@mui/lab/LoadingButton'
import CategoryBtn from './CategoryBtn'
import moment from 'moment'

const PostCard = ({loading = false, post}) => {

  console.log('PostInfo :', post)

  let {date} = post
  date = moment(date).format('D.M.YY')

  const [category, setCategory] = useState(null)
  const {infoCategories, categories} = useContext(GlobalCategories)
  const [link, setLink] = useState(null)

  useEffect(() => {
    const getCategory = () => {
      const categoryIds = post.categories
      console.log('CAT IDs:', categoryIds)
      let categoryId = 0
      categoryIds.forEach(id => {
        if (!infoCategories.includes(id)) {
          categoryId = id
        }
      })
      console.log('POST CAT id:', categoryId)
      const fullCategory = categories.filter(cat => cat.id === categoryId)
      console.log('CATEGORY: ', fullCategory)
      setCategory(fullCategory[0])
      getPostLink(fullCategory[0])
    }
    const getPostLink = (category) => {
      const link = `https://www.landkreis-landshut-erleben.de/${category.slug}/${post?.slug}`
      setLink(link)
    }
    if (!loading) {
      getCategory()
    }
  }, [loading])

  function removeTags(str) {
    if ((str === null) || (str === '')) {return false}
    else {str = str.toString()}
    return str.replace(/(<([^>]+)>)/ig, '')
  }

  return (<Card sx={{
    width: {
      xs: 'min(250px,80%)',
      md: '40%',
      lg: 'min(20%,400px)',
      flexShrink: 0,
      position: 'relative',
      maxHeight: '800px',
    },
  }}>
    <CardMedia sx={{position: 'relative', width: '100%', pb: '100%'}}>
      <PostCardMedia mediaId={post?.featured_media} postLoading={loading}/>
    </CardMedia>
    <CardContent sx={{mb: '40px'}}>
      <Stack direction={'row'} justifyContent={'space-between'}
             alignItems={'center'}>
        <Box>
          {loading && category !== null ?
              <LoadingButton loading/> :
              <CategoryBtn category={category}/>}
        </Box>
      </Stack>
      <Divider sx={{mb: 1}}/>
      <Typography gutterBottom variant="h5" component="div"
                  textAlign={'left'}
                  sx={{fontWeight: 'bold'}}>
        {loading ?
            <Skeleton animation={'wave'} variant={'text'}/> :
            post.title.rendered}
      </Typography>
      <Typography variant="body2" color="text.secondary"
                  textAlign={'left'}>{loading ?
          <Skeleton animation={'wave'} variant={'text'}/> :
          removeTags(post.excerpt.rendered.substr(0, 100)) + '…'}
      </Typography>
    </CardContent>
    <CardActions sx={{position: 'absolute', bottom: 0}}>
      <LoadingButton loading={loading || link === null} size={'small'}
                     href={link} target={'_blank'}
                     variant={'contained'}>Weiterlesen</LoadingButton>
    </CardActions>
  </Card>)
}
export default PostCard