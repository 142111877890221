import React, {useEffect, useState} from 'react'
import {fetchAPI} from '../../../lib/api'
import {Box, Skeleton} from '@mui/material'

const PostCardMedia = ({mediaId, postLoading}) => {
  const [loading, setLoading] = useState(true)
  const [media, setMedia] = useState({})
  const [source, setSource] = useState('')

  useEffect(() => {
    const getMedia = async () => {
      const media = await fetchAPI('/media/' + mediaId, {
        _fields: ['media_details', 'alt_text'],
      })
      setMedia(media)
      setSource(media.media_details.sizes.large === undefined ?
                    media.meida_detials.sizes.medium.source_url :
                    media.media_details.sizes.large?.source_url)
      setLoading(false)
    }
    if (!postLoading) {
      getMedia().catch(console.error)
    }
  }, [postLoading])

  return (<Box sx={{
    position: 'absolute', width: '100%', height: '100%', top: 0, left: 0,
  }}>
    {loading ?
        <Skeleton variant={'square'} animation={'wave'}
                  sx={{minHeight: '100%', width: '100%'}}/> :
        <img src={source}
             style={{width: '100%', height: '100%', objectFit: 'cover'}}
             alt={media.alt_text}/>}
  </Box>)

}
export default PostCardMedia