import qs from 'qs'

export const BASE_URL = `https://landkreis-la.de/api`

export const getApiURL = (path = '') => {
  return `${BASE_URL}/wp-json/wp/v2${path}`
}

export const fetchAPI = async (path, urlParamsObject = {}, options = {}) => {
  
  // Build request URL
  const queryString = qs.stringify(urlParamsObject)
  const requestUrl = `${getApiURL(
      `${path}${queryString ? `?${queryString}` : ''}`)}`

  console.log('REQUEST: ', requestUrl, urlParamsObject)

  // Trigger API Call
  const data = await fetching(requestUrl, options)
  return data
}

export const fetchMedia = async id => await fetchAPI(`/media/${id}`)

export const fetchMenu = async () => {
  const menu = await fetching(`${BASE_URL}/wp-json/wp-api-menus/v2/menus/6`)

  return {
    ...menu,
    items: menu.items.map(item => ({...item, url: sanitizeLinks(item.url)})),
  }

}

export const fetchLogo = async () => {
  const globalRes = await fetching(`${BASE_URL}/wp-json`)
  const logoMediaId = globalRes['site_logo']
  return await fetchMedia(logoMediaId)
}

const fetching = async (url, options = {}) => {
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
    }, ...options,
  }

  const response = await fetch(url, mergedOptions)

  // Handle Response
  if (!response.ok) {
    console.error(response.json())
  }
  return response.json()
}

export const sanitizeLinks = link => link.replace(BASE_URL, '')


