import News from './components/News'
import {
  Box, Button, CircularProgress, Stack, ThemeProvider, Typography,
} from '@mui/material'
import theme from './lib/theme'
import {createContext, useEffect, useState} from 'react'
import {fetchAPI} from './lib/api'

const infoCategories = [7, 41, 40, 1]

export const GlobalCategories = createContext({categories: [], infoCategories})

function App() {

  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const getCategories = async () => {
      const cats = await fetchAPI('/categories', {
        //_fields: ['id', 'slug', 'name', 'color'],
      })

      setCategories(cats.filter(cat => (!infoCategories.includes(cat.id))))
      setLoading(false)
    }
    getCategories().catch(console.error)
  }, [])

  return (<GlobalCategories.Provider value={{categories, infoCategories}}>
    <ThemeProvider theme={theme}>
      <Box className="App" sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Box>
          <Typography variant={'h2'}>Geschichten aus dem Landkreis</Typography>
          {loading ?
              <Box sx={{display: 'flex', justifyContent: 'center', py: 3}}>
                <CircularProgress sx={{my: 3}}/>
              </Box> :
              <News/>}
          <Stack alignItems={'center'} justifyContent={'center'}>
            <Button href={'https://www.landkreis-landshut-erleben.de'}
                    target={'_blank'}
                    variant={'neutral'}>Mehr Geschichten</Button>
          </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  </GlobalCategories.Provider>)

}

export default App
